input, textarea, select {
  background: transparent;
  color: $accent;
  border: 1px solid $accent;
  border-radius: 0;
  padding: 10px;
  font: inherit;
  appearance: none;

  &:focus, :active {
    border-color: $color;
    outline: 1px solid $color;
  }

  &:active {
    box-shadow: none;
  }
}

select {
  background: $background;

  option {
    background: $background;
  }
}

::placeholder {
  color: color-mod($accent) a(50%);
}
